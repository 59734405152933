import React, { useEffect, useState } from "react";
import logo from "../logotal.png";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import "./MainNavbar.css";
import { useLocation } from "react-router-dom";

const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export default function MainNavbar(props) {
  const { headerData, currentJamaatName, userFullName, ageLimit } = props;
  const navigate = useNavigate();
  let location = useLocation();

  const userid = getCookie("user_id");
  console.log(userid);

  const handleUrl = (url) => {
    if (url === "home") {
      navigate("/");
    } else if (url === "deeni") {
      navigate("/deeni");
    } else if (url === "mauze") {
      navigate("/mauze-profile-entry");
    } else if (url === "task") {
      navigate("/task");
    } else if (url === "institude") {
      navigate("/institude");
    }
  };

  const onActive = {
    backgroundColor: "#fff",
    color: "#000",
  };

  return (
    <Navbar
      className="largeContainer"
      style={{ backgroundColor: "#002147", height: "120px" }}
    >
      <Container fluid>
        <div className="d-flex justify-content-between nav-container">
          <a href="https://www.talabulilm.com">
            <img className="" src={logo} alt="img" />
          </a>
          {/* <h3>Current Education Status for Age: {ageLimit.lowerAge} - {ageLimit.higherAge}<br />{`${headerData ? headerData?.jamaat : ""}`} </h3> */}
          <h3>
            Education Dashboard
            {`${currentJamaatName ? " - " + currentJamaatName : ""}`}{" "}
          </h3>
          <div className="d-flex">
            <div className="image-header">
              {userFullName?.name}
              <br />
              <div>
                <a
                  className="top-nav-link "
                  style={{ ...(location.pathname === "/" ? onActive : "") }}
                  onClick={() => handleUrl("home")}
                >
                  Home
                </a>
                <a
                  className="top-nav-link "
                  style={{
                    ...(location.pathname === "/deeni" ? onActive : ""),
                  }}
                  onClick={() => handleUrl("deeni")}
                >
                  Deeni
                </a>
                {/* <a
                  className="top-nav-link"
                  style={{
                    ...(location.pathname === "/mauze-profile-entry"
                      ? onActive
                      : ""),
                  }}
                  onClick={() => handleUrl("mauze")}
                >
                  Education Survey
                </a> */}
                <a
                  className="top-nav-link"
                  style={{
                    ...(location.pathname === "/institude" ? onActive : ""),
                  }}
                  onClick={() => handleUrl("institude")}
                >
                  Institude
                </a>
                {/* <a
                  className="top-nav-link"
                  style={{ ...(location.pathname === "/task" ? onActive : "") }}
                  onClick={() => handleUrl("task")}
                >
                  My Task
                </a> */}
              </div>
            </div>
            <img
              className="image-content"
              src={`https://www.talabulilm.com/mumin_images/${userid}.png`}
              alt="img"
            />
          </div>
        </div>
      </Container>
    </Navbar>
  );
}
