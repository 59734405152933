import React, { useEffect, useState } from "react";
import AdminPage from "../components/AdminPage";
import { Buffer } from "buffer";
import useFetch from "../useFetch";
import { ToastContainer } from "react-toastify";
import MainNavbar from "../components/MainNavbar";
import MobileNavbar from "../components/MobileNavbar";
import { useSelector } from "react-redux";

const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const getTokenFromCookie = () => {
  if (getCookie("user_its") === null) {
    // Live website
    window.location.replace(
      "https://www.its52.com/Login.aspx?OneLogin=MHB&tlbre=YWRtaW4udGFsYWJ1bGlsbS5jb20="
    );
    // test website
    // window.location.replace(
    //   "https://www.its52.com/Login.aspx?OneLogin=MHB&tlbre=aHR0cHM6Ly90ZXN0YWFtaWxzYWhlYi50YWxhYnVsaWxtLmNvbS8="
    // );
    return;
  }

  var username = getCookie("user_its").split(",")[0];
  var password = getCookie("ver");
  // localStorage.setItem("username", username);

  // var adminToken = localStorage.getItem("admin-token");
  // console.log('adminToken: ', adminToken);

  // let password = md5(`${50476733}tlbilm@12345678+515253`);
  const token = Buffer.from(`${username}:${password}`, "utf8").toString(
    "base64"
  );
  localStorage.setItem("admin-token", token);
  // token && setToken(token);
  return token;
};

export default function AppRoutes() {
  const { isLoginSuccess } = useSelector(({ common }) => common);
  const [sidebarData, setSideBarData] = useFetch();
  const [allJamaatList, setAllJamaatList] = useFetch();
  const [allJamiaatList, setAllJamiaatList] = useFetch();
  const [apiData, setApiData] = useFetch();
  const [headerData, setHeaderData] = useFetch();
  const [userFullName, setUserFullName] = useFetch();
  const [currentJamaatName, setCurrentJamaatName] = useState();
  const [currentJamiaatName, setCurrentJamiaatName] = useState();
  const [EduStatus, setEduStatus] = useState(
    "Araz done for currently studying"
  );
  const [downloadRecord, setDownloadRecord] = useState(
    "https://talabulilm.com/profile/csvdownload.php?cmd=razastatus&raza_status=Araz%20done"
  );

  const [ageLimit, setAgeLimit] = useState({ lowerAge: 3, higherAge: 27 });
  const [currentJamiaatList, setCurrentJamiaatList] = useState();
  const [currentJamaatList, setCurrentJamaatList] = useState();
  const [currentJamaatId, setCurrentJamaatId] = useState();
  const [currentGenderName, setCurrentGenderName] = useState("A");
  const [currentJamiaattId, setCurrentJamiaattId] = useState();
  const [currentData, setCurrentData] = useState([]);
  const defaultSort = { field: "", direction: "" };
  const [sort, setSort] = useState(defaultSort);
  const [token, setToken] = useState(false);

  const handleRequest = (verb, lable, downlaod) => {
    setApiData("");
    setSort(defaultSort);
    setApiData(`profile/admin/${verb}`);
    setEduStatus(lable);
    setDownloadRecord(downlaod);
    setCurrentData(apiData);
  };

  const handleSubmitAge = (lowerAgeLimit, higherAgeLimit) => {
    setAgeLimit({ lowerAge: lowerAgeLimit, higherAge: higherAgeLimit });
  };

  useEffect(() => {
    if (
      ageLimit &&
      currentJamaatId &&
      currentJamaatId !== "All" &&
      currentJamiaattId !== "All"
    ) {
      setSideBarData(
        `profile/admin/adminFilters/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentGenderName}/${currentJamiaattId}/${currentJamaatId}`
      );
    } else if (ageLimit && currentJamiaattId && currentJamiaattId !== "All") {
      setSideBarData(
        `profile/admin/adminFilters/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentGenderName}/${currentJamiaattId}`
      );
    } else if (ageLimit) {
      setSideBarData(
        `profile/admin/adminFilters/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentGenderName}`
      );
    }
  }, [ageLimit, currentJamiaattId, currentJamaatId, currentGenderName]);

  useEffect(() => {
    allJamiaatList &&
      allJamiaatList.length > 0 &&
      setCurrentJamiaatList(
        [{ jamiaat_id: "All", jamiaat: "All" }, ...allJamiaatList]?.map(
          (item) => {
            return { jamiaat_id: item.jamiaat_id, jamiaat: item.jamiaat };
          }
        )
      );
  }, [allJamiaatList]);

  useEffect(() => {
    if (sidebarData) {
      setSort(defaultSort);
      if (ageLimit && currentJamaatId && currentJamaatId !== "All") {
        handleRequest(
          `razaUserList/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentGenderName}/1/${currentJamiaattId}/${currentJamaatId}/Araz%20done`,
          "Araz done for currently studying",
          "https://talabulilm.com/profile/csvdownload.php?cmd=razastatus&raza_status=Araz%20done"
        );
      } else if (ageLimit && currentJamiaattId && currentJamiaattId !== "All") {
        handleRequest(
          `razaUserList/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentGenderName}/1/${currentJamiaattId}/Araz%20done`,
          "Araz done for currently studying",
          "https://talabulilm.com/profile/csvdownload.php?cmd=razastatus&raza_status=Araz%20done"
        );
      } else if (ageLimit) {
        handleRequest(
          `razaUserList/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentGenderName}/1/Araz%20done`,
          "Araz done for currently studying",
          "https://talabulilm.com/profile/csvdownload.php?cmd=razastatus&raza_status=Araz%20done"
        );
      }
    }
  }, [sidebarData]);

  useEffect(() => {
    if (currentJamiaatList && currentJamiaatList.length > 0) {
      const inititalData = allJamiaatList?.map((item) => {
        return { jamaat_id: item, jamaat: item };
      });
      setCurrentJamiaattId("All");
    }
  }, [currentJamiaatList]);

  useEffect(() => {
    currentJamiaattId !== "All" &&
      currentJamiaattId &&
      setAllJamaatList(`profile/admin/jamaatList/${currentJamiaattId}`);
  }, [currentJamiaattId]);

  useEffect(() => {
    let token = getTokenFromCookie();
    token && setToken(token);
    if (allJamaatList && allJamaatList.length > 0) {
      const inititalData = [
        { jamaat_id: "All", jamaat: "All" },
        ...allJamaatList,
      ];
      setCurrentJamaatList(inititalData);
    } else {
      setCurrentJamaatName("All");
      setCurrentJamaatList([{ jamaat: "All", jamaat_id: "All" }]);
    }
  }, [allJamaatList]);

  useEffect(() => {
    setHeaderData("profile/admin/details");
    setUserFullName("profile/");
    setAllJamiaatList("profile/admin/jamiaatList");
  }, [token]);

  useEffect(() => {
    setCurrentData(apiData);
  }, [apiData]);

  const handleSort = (field) => {
    let direction = sort.direction;
    if (field === sort.field) {
      direction = sort.direction === "asc" ? "desc" : "asc";
    } else {
      direction = "asc";
    }
    const sortedData = currentData?.sort((a, b) => {
      if (field === "age")
        return direction === "asc" ? a[field] - b[field] : b[field] - a[field];
      return direction === "asc"
        ? a[field].localeCompare(b[field])
        : b[field].localeCompare(a[field]);
    });
    setCurrentData(sortedData);
    setSort({ field: field, direction: direction });
  };
  const handleJamiaattChange = (jamiaatId, jamaat) => {
    setCurrentJamaatId();
    if (jamiaatId !== "All") {
      setAllJamaatList(`profile/admin/jamaatList/${jamiaatId}`);
    } else {
      setCurrentJamaatList([{ jamaat: "All", jamaat_id: "All" }]);
    }
    setCurrentJamiaattId(jamiaatId);
    setCurrentJamaatName("All");
  };
  const handleGenderChange = (genderId) => {
    setCurrentGenderName(genderId);
  };
  const handleJamaatChange = (jamaatId, jamaat) => {
    if (jamaatId !== "All") {
      setCurrentJamaatId(jamaatId);
    } else {
      setCurrentJamaatId();
    }
  };

  useEffect(() => {
    if (currentJamaatId) {
      const jamaat = allJamaatList?.filter((item) =>
        item.jamaat_id === currentJamaatId ? item.jamaat : ""
      );
      setCurrentJamaatName(jamaat?.[0]?.jamaat);
      // handleRequest(
      //   `razaUserList/${ageLimit.lowerAge}/${ageLimit.higherAge}/${currentJamaatId}/Araz%20done`,
      //   "Araz done for currently studying",
      //   "cmd=razastatus&raza_status=Araz%20done"
      // );
      // currentJamaatId && setMyTask(`profile/admin/taskList/${currentJamaatId}`);
    } else {
      setCurrentJamaatName("All");
    }
  }, [currentJamaatId]);

  useEffect(() => {
    if (currentJamiaattId) {
      const jamiaat = currentJamiaatList?.filter((item) =>
        item.jamiaat_id === currentJamiaattId ? item.jamiaat : ""
      );
      setCurrentJamiaatName(jamiaat?.[0]?.jamiaat);
      setCurrentJamaatList([{ jamaat: "All", jamaat_id: "All" }]);
    }
  }, [currentJamiaattId]);

  return (
    <>
      <MainNavbar
        headerData={headerData}
        currentJamaatName={currentJamaatName}
        userFullName={userFullName}
        ageLimit={ageLimit}
      />
      <MobileNavbar headerData={headerData} />
      <AdminPage
        sidebarData={sidebarData}
        currentJamiaatName={currentJamiaatName}
        currentJamaatName={currentJamaatName}
        currentData={currentData}
        sort={sort}
        handleJamiaattChange={handleJamiaattChange}
        handleJamaatChange={handleJamaatChange}
        allJamiaatList={currentJamiaatList}
        allJamaatIds={currentJamaatList}
        EduStatus={EduStatus}
        downloadRecord={downloadRecord}
        handleRequest={handleRequest}
        handleSort={handleSort}
        jamaatId={currentJamaatId}
        handleSubmitAge={handleSubmitAge}
        handleGenderChange={handleGenderChange}
        currentGenderName={currentGenderName}
      />
      <ToastContainer />
    </>
  );
}
