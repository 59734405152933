import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AppRoutes from "./Routes/AppRoutes";
import DeeniRoutes from "./Routes/DeeniRoutes";
import Institude from "./components/Institude/Institude";
import { store } from "./store";
import { Provider, useDispatch } from "react-redux";
import { setIsLoginSuccess } from "./store/reducers/common";
import { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import useFetch from "./useFetch";

import Login from "./components/Login";
import { ProtectedRoute } from "./Routes/PrivateRouter";
export default function App() {
  const dispatch = useDispatch();

  const [userDetails, setUserDetails] = useFetch();

  useEffect(() => {
    if (localStorage.getItem("admin-token")) {
      dispatch(setIsLoginSuccess(true));
    }
  }, []);

  useEffect(() => {
    setUserDetails(`profile/admin/adminDetails`);
  }, []);

  return (
    <>
      {userDetails && userDetails.success === false ? (
        <div>
          <h1> You are not authorised to view this page</h1>
          <a href="https://talabulilm.com">Go back to talabulilm.com</a>
        </div>
      ) : (
        <BrowserRouter>
          <Provider store={store}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <AppRoutes />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/deeni"
                element={
                  <ProtectedRoute>
                    <DeeniRoutes />
                  </ProtectedRoute>
                }
              />
              {/* <Routes/> */}
              <Route
                path="/institude"
                element={
                  <ProtectedRoute>
                    <Institude />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Provider>
        </BrowserRouter>
      )}
    </>
  );
}
